import { useContext } from "react";
import { AppContext } from "../../domain/AppContextProvider";
import { AppContextType, Reservation } from "../../domain/AppContextType";
import { Commune } from "../../domain/entities/Commune";
import { Prestation } from "../../domain/entities/Prestation";
import { convertMinsToHrsMins, formatEuro } from "../../domain/utils";


type ReservationTableProps = {
    reservation: Reservation;
}
export function ReservationTable({ reservation }: ReservationTableProps) {
    const { appConfiguration } = useContext(AppContext) as AppContextType;
    const totalHT = reservation.prestation!.prixHT + (reservation.commune?.fraisKilometrique ?? 0);
    const tauxTVA = (appConfiguration?.tauxTVA! / 100);
    const totalTVA = totalHT * tauxTVA;
    const totalTTC = totalHT * (1 + tauxTVA);

    return (<div className="pt-3">
        <table className="w-full">
            <tbody>
                <tr >
                    <td className="font-bold w-1/4">Date</td>
                    <td > {Intl.DateTimeFormat("fr-FR", { dateStyle: "medium" }).format(reservation.date!)}
                    </td>
                </tr>
                <tr>
                    <td className="font-bold min-w-1/4">Heure</td>
                    <td > {Intl.DateTimeFormat("fr-FR", { timeStyle: "short", hour12: false }).format(reservation.date!)}
                    </td>
                </tr>
                <tr>
                    <td className="font-bold">Duree</td>
                    <td >{convertMinsToHrsMins(reservation.prestation?.duree!)}</td>
                </tr>
                <tr>
                    <td className="font-bold">Service</td>
                    <td >
                        {reservation.prestation?.nom} {reservation.prestation?.description} </td>
                </tr>
                <tr>
                    <td className="font-bold">Prix HT</td>
                    <td >{formatEuro(reservation.prestation!.prixHT)}</td>
                </tr>
                {reservation.commune && <tr>
                    <td className="font-bold">Frais </td>
                    <td >{reservation.commune!.nom} {formatEuro(reservation.commune!.fraisKilometrique)}</td>
                </tr>}

                <br></br>

                <tr >
                    <td  colSpan={2}><hr className="mb-3"></hr></td>
                </tr>

                {appConfiguration?.afficherPrixHT ? <>
                    <tr>
                        <td>Total HT</td>
                        <td className="text-right font-bold">{formatEuro(totalHT)}</td>
                    </tr>
                </> : <>
                    <tr>
                        <td>Total HT</td>
                        <td className="text-right">{formatEuro(totalHT)}</td>
                    </tr>
                    <tr>
                        <td className="whitespace-nowrap">Total TVA ({Intl.NumberFormat("fr-FR", { style: "percent" }).format(tauxTVA)})</td>
                        <td className="text-right">{formatEuro(totalTVA)}</td>
                    </tr>
                    <tr>
                        <td className="font-bold">Total TTC</td>
                        <td className="text-right font-bold">{formatEuro(totalTTC)}</td>
                    </tr>
                </>}
            </tbody>
        </table></div>)

}