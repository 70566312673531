import React, { useContext } from "react";
import { Button, CardBody, Card, Spinner, Typography, Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { Prestation } from "../../domain/entities/Prestation";
import { useDescriptionViewModel } from "./useViewModel";
import { AppConfiguration } from "../../domain/entities/AppConfiguration";
import { AppContext } from "../../domain/AppContextProvider";
import { AppContextType } from "../../domain/AppContextType";
import { convertMinsToHrsMins } from "../../domain/utils";

type SelectionPrestationFormProps = {
  description: AppConfiguration;
  onStepFilled: (selectedPrestation: Prestation) => void;
}

export function SelectionPrestationForm({ description, onStepFilled }: SelectionPrestationFormProps) {
  const { appConfiguration } = useContext(AppContext) as AppContextType;
  const { groupPrestations , isLoading, open, handleOpen} = useDescriptionViewModel(description);

  
  if (isLoading) {
    return <div className="h-screen flex items-center justify-center">
      <Spinner className="h-12 w-12" />
    </div>
  }

  return (<div className="h-full flex flex-col mt-2">
    {Object.keys(groupPrestations).map((categorie) => (
      <div key={categorie}>

        <Accordion open={open === categorie}>
          <AccordionHeader onClick={() => handleOpen(categorie)} className="border-accent"> {categorie}</AccordionHeader>
          <AccordionBody>
            {groupPrestations[categorie].map((item: Prestation) => (
              <Card key={item.id} className="mt-5" >

                <CardBody className="p-2 hover:cursor-pointer" onClick={() => onStepFilled(item)}>
                  <div className="flex flex-col">
                    <Typography variant="paragraph" className="font-bold" >{item.nom}</Typography>
                    <Typography variant="small"  >{item.description}</Typography>
                    <div className="flex flex-row items-center justify-between ">
                      <div>
                        <span className="mr-3">{item.formatPrix(appConfiguration?.afficherPrixHT!)}</span>
                        <span className="mr-3">-</span>
                        <span >{convertMinsToHrsMins(item.duree)}</span>
                      </div>

                      <Button variant="filled" className="bg-primary text-background" size="sm" >Choisir</Button>
                    </div>
                  </div>
                </CardBody>


              </Card>
            ))}
          </AccordionBody>
        </Accordion>


      </div>
    ))}
  </div>);

}