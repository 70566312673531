import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Spinner,
} from "@material-tailwind/react";
import { Prestation } from "../../domain/entities/Prestation";
import { CalendarDaysIcon, CheckBadgeIcon } from "@heroicons/react/24/outline";

import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from "moment";
import { Coordonnee } from "../../domain/entities/Coordonnee";
import { useViewModel } from "./useViewModel";
import { ReservationTable } from "../ReservationTable/ReservationTable";
import { ButtonReservationFallback } from "../ButtonReservationFallback/ButtonReservationFallback";
import { AppContextType, Reservation } from "../../domain/AppContextType";
import { useContext } from "react";
import { AppContext } from "../../domain/AppContextProvider";

type ConfirmationStepProps = {
  reservation: Reservation;
  onConfirmationSuccess: () => any;
}


export function ConfirmationStep({ reservation, onConfirmationSuccess }: ConfirmationStepProps) {
  const {appConfiguration} = useContext(AppContext) as AppContextType;
  const { isLoading, errors, onChangeCGV, isCGEValid, onValidate } = useViewModel(reservation, onConfirmationSuccess);
  return (
    <Card color="transparent" shadow={false}>
      <Typography className="text-center my-3" variant="h4" color="blue-gray">
        Confirmez votre réservation
      </Typography>
      <ReservationTable reservation={reservation} />
      <Checkbox
        onChange={onChangeCGV}
        className="text-secondary border-secondary  before:bg-secondary"
        label={<Typography
          variant="small"
          className="flex items-center font-normal text-text"
        >
          J'accepte les
          <a
            href={appConfiguration!.lienCGV ?? ""}
            target='_blank'
            className="font-bold text-secondary"
          >
            &nbsp;Termes et Conditions
          </a>
        </Typography>}
        containerProps={{ className: "-ml-2.5" }} crossOrigin={undefined} />
      {errors.cge && <p className="text-red-600">{errors.cge}</p>}

      {errors.system && <><Typography color="red" className="mt-1 font-normal mb-3">
        {errors.system}
      </Typography>
      <Typography  className="mt-1 font-normal mb-3">
        Pour prendre rendez-vous, veuillez nous contacter par e-mail <ButtonReservationFallback reservation={reservation} />  </Typography>
      </>}
      <Button className="mt-6 bg-primary text-background" fullWidth  onClick={onValidate} disabled={isLoading || !isCGEValid}>
        {isLoading ? <center><Spinner className="h-4 w-4" /></center> : "Valider ma réservation"}
      </Button>


    </Card>
  );
}