import { formatEuro } from "../utils";

export class Prestation {
    id:number;
    nom: string;
    description: string;
    duree: number;
    categorie: string;
    prixHT: number;
    prixTTC: number;

    constructor(id:number, nom: string, description: string, duree: number, categorie: string, prixHT: number, prixTTC: number, ) {
        this.id = id;
        this.nom = nom;
        this.description = description;
        this.duree = duree;
        this.categorie = categorie;
        this.prixHT = prixHT;
        this.prixTTC = prixTTC;
    }


    formatPrix(afficherPrixHT: boolean) : string {
        return formatEuro(afficherPrixHT ? this.prixHT : this.prixTTC);
    }


}
export type GroupedPrestations = Record<string, Prestation[]>;

