export function formatEuro(ammount: number) : string {
    return Intl.NumberFormat("fr-FR", { style: 'currency', currency: 'EUR' }).format(ammount);
}


export  function convertMinsToHrsMins(minutes: number) {
    let h : number = Math.floor(minutes / 60);
    var m = minutes % 60;

    return h + 'H' + m + "min";
  }